.phoneme-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: white;
    margin-top: 50px;
    padding: 20px;
    box-sizing: border-box;
  }

.phoneme-container .content{
    min-width: 300px; /* 설명 텍스트의 최대 너비를 제한 */
}

.phoneme-container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 24px;
}
  
.phoneme-container .app-title {
    color: black; /* 텍스트 색상을 흰색으로 설정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
    margin: 0; /* 제목을 화면 가운데에 위치시킴 */
    font-size: 24px;
  }

.phoneme-container .description{
    margin-top: 20px; /* 제목과 설명 텍스트 사이의 간격 */
    padding: 10px; /* 텍스트 주변에 패딩 추가 */
    border-radius: 10px; /* 둥근 모서리 스타일링 */
    background-color: white; /* light grey background */
  }

.phoneme-container .description-text {
    color: #000000; /* 텍스트 색상을 흰색으로 설정 */
    text-align: start;
    line-height: 1.5; /* 줄 간격을 조절 */
    font-size: 16px; /* 텍스트 크기 설정 */
}

.phoneme-container .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 8px 0;
  }

  .phoneme-list{

    margin-top: 20px; /* 제목과 설명 텍스트 사이의 간격 */
    padding: 10px; /* 텍스트 주변에 패딩 추가 */
    border-radius: 10px; /* 둥근 모서리 스타일링 */
    background-color: #f0f0f0; /* light grey background */

    text-align: center;
    font-size: 18px;
  }

  .phoneme-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* 대형 스크린에 대해 한 행에 9개 */
    gap: 4px;

    margin-top: 8px;
  }
  
  .phoneme-cell {
    border: 2px solid #ccc;

    min-width: 1.6rem;
    min-height: 1.6rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }

  .phoneme-cell.selected {
    border-color: blue;
    background-color: white;
  }

  .phoneme-cell img {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }
  

/* 창 너비가 560px 이상일 때의 검색창 너비 */
@media (min-width: 560px) {
    .phoneme-container .content {
        width: 560px;
    }

    .phoneme-container .app-title {
        font-size: 40px;
    }  

    .phoneme-list{
        font-size: 24px;
    }

    .phoneme-cell{
        min-width: 2rem;
        min-height: 2rem;
    }


    
}
    
    /* 창 너비가 672px 이상일 때의 검색창 너비 */
    @media (min-width: 672px) {
    .phoneme-container .content{
        width: 672px;
    }

    .phoneme-container .app-title {
        font-size: 48px;
    }
}
