.phoneme-rule {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
}

.phoneme-rule div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 3rem;
    min-height: 3rem;
}

.phoneme-rule div img {
    max-width: 3rem;
    max-height: 3rem;
}

.phoneme-rule span {
    width: calc(100% - 7rem);
    margin: 0px 8px;
    font-size: 12px;
}

.detail-button{
    max-width: 3rem;
    max-height: 3rem;
    cursor: pointer;
    background-color: blue; /* Light grey background */
    border: 1px solid blue; /* Grey border */
    color: white;
    border-radius: 5px; /* Rounded corners */
    font-size: 1.5rem;
    text-align: center;
    line-height: 3rem; /* Vertical align text */
}

  /* 창 너비가 560px 이상일 때의 검색창 너비 */
  @media (min-width: 560px) {
    .phoneme-rule span {
        margin: 0px 18px;
        font-size: 18px;
    }
    
  }
