/* SearchWord.css */
.search-history-container {
    display: flex;
    max-width: 300px;
    overflow-x: auto;
    gap: 2px;
    padding: 0px;
    margin-top: 8px;
    white-space: nowrap;
  }
  
  .history-item {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 8px 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    min-width: max-content;
    position: relative;
  }
  
  .history-item span {
    margin-right: 16px;
    cursor: pointer;
    user-select: none;
  }
  
  .clear-icon {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #a3a3a3;
  }

  /* Styling the scrollbar for better appearance */
.search-history-container::-webkit-scrollbar {
    height: 5px;
}

.search-history-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.search-history-container::-webkit-scrollbar-thumb {
    background-color: #888;
}

.search-history-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* 창 너비가 560px 이상일 때의 검색창 너비 */
@media (min-width: 560px) {
  .search-history-container {
    max-width: 449px;
  }
}

/* 창 너비가 672px 이상일 때의 검색창 너비 */
@media (min-width: 672px) {
  .search-history-container {
    max-width: 561px;
  }
}