.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
  background-color: #FFEB3B; /* Bright yellow */
  color: #333; /* Dark text for contrast */
  border: none;
  border-radius: 25px; /* Rounded corners */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.15);
  text-decoration: none; /* Removes underline from links */
  outline: none; /* Removes the outline */
  padding: 0 20px; /* Adds padding to the sides */
}

.chat-button:hover {
  background-color: #FDD835; /* Slightly darker yellow on hover */
}

.chat-button:focus {
  outline: none; /* Ensures no outline appears on focus */
}

.chat-text {
  margin-right: 16px;
  color: purple; /* Dark text for contrast */
  font-size: 16px; /* Adjust as needed */
  font-weight: bold;
}

.chat-icon {
  color: #333; /* Dark text for contrast */
  font-size: 20px; /* Adjust as needed */
}
