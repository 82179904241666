.ipa-sub-bundle {
    border: 1px solid #000;
    margin-right: 1px;
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    fill: black;
}

/* A. final_consonant가 없는 경우 */
.sub-first-consonant-null {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 1rem;
}


.sub-vowel-right {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 1.5rem;
}

.sub-vowel-bottom {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    width: 1.5rem;
    height: 0.5rem;
}

.sub-vowel-full {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    transform: none;
}

/* B. final_consonant가 있는 경우 */
.sub-first-consonant-bottom.with-final {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 0.5rem;
}

.sub-final-consonant {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 0.5rem;
}

.sub-vowel-right.with-final {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(0);
    width: 0.5rem;
    height: 1rem;
}

.sub-vowel-bottom.with-final {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5rem;
    height: 0.5rem;
}

.sub-vowel-full.with-final {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1.5rem;
    height: 1rem;
}

/* Hover 효과 */

.ipa-sub-bundle img:hover {
    outline: 2px solid yellow;
}
