.card {
    margin: 16px auto 56px auto;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.1);
    padding: 16px; /* 기본 패딩을 조금 더 크게 설정 */
    box-sizing: border-box;
    background-color: white;
}

.card h1 {
    margin: 0;
}

/* Audio Section */
.ipa-audio {
    display: flex;
    align-items: center;
}
  
.audio-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    padding: 4px 8px;
    background-color: blue;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

audio {
    display: none;
}

.ipa-text {
    font-size: 20px;
    margin-left: 10px;
    color: grey;
    font-weight: bold;
}

/* Read Section */
/* .ipa-manual {

} */

.manual-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding: 4px 8px;
    background-color: blue;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

.manual-title{
    margin-left: 10px;
    font-weight: bold;
}

.manual-text{
    margin-top: 4px;
    border: 2px solid blue;
    border-radius: 4px;
    padding: 4px;
}

/* Posthangul Section */
/* IPA Bundles 스타일링 */
.ipa-content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: white;
}

.ipa-bundles {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;
    margin: 16px 0px; /* or any desired margin */
    padding: 0;
}

.signature{
    position: absolute;
    right: 10px;
    bottom: -10px;
    display: none;
    flex-direction: row;
    align-items: center;
}

.signature p{
    font-size: 10px;
}

.signature img{
    margin-right: 2px;
    width: 10px;
    height: 10px;
}


.card .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 0 !important;
}

/* Meaning Section */
.means-list {
    margin: 0; /* 상하 margin 동일하게 설정 */
    padding: 0;  /* 패딩 동일하게 설정 */
    border-radius: 5px;
}

.mean-item {
    margin-top: 8px;
}