.navbar {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    /* padding: 0.5rem 1rem; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }
  
  .navbar-logo img {
    height: 40px;
  }
  
  .navbar-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 180px;
  }
  
  .navbar-item {
    color: #007bff;
    text-decoration: none;
  }
  
  .navbar-item:hover {
    text-decoration: underline;
  }
  