.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: white;
    /* margin-top: 50px; */
    padding: 20px;
    box-sizing: border-box;
  }

  .content{
    min-width: 300px; /* 설명 텍스트의 최대 너비를 제한 */
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 24px;
}
  
  .app-title {
    color: black; /* 텍스트 색상을 흰색으로 설정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
    margin: 0; /* 제목을 화면 가운데에 위치시킴 */
    font-size: 24px;
  }

  .app-logo{
    height: 36px;
  }

  .description{
    margin-top: 20px; /* 제목과 설명 텍스트 사이의 간격 */
    padding: 10px; /* 텍스트 주변에 패딩 추가 */
    border-radius: 10px; /* 둥근 모서리 스타일링 */
    background-color: #f0f0f0; /* light grey background */
  }

  .description-text {
    color: #000000; /* 텍스트 색상을 흰색으로 설정 */
    text-align: center;
    line-height: 1.5; /* 줄 간격을 조절 */
    font-size: 16px; /* 텍스트 크기 설정 */
}

  .container .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 16px 0;
  }

  .example {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .example span{
    font-size: 16px; /* 텍스트 크기 설정 */
  }

  .example .ipa-bundles{
    margin: 10px !important;
    padding: 0 !important;
  }

  /* 창 너비가 560px 이상일 때의 검색창 너비 */
  @media (min-width: 560px) {
    .container{
      margin-top: 50px;
    }
    
    .content {
      width: 449px;
    }

    .app-title {
      font-size: 40px;
    }

    .app-logo {
      height: 48px;
    }

    .description-text, .example span {
      font-size: 18px; /* 스크린 크기가 커질 때 텍스트 크기 증가 */
    }
    
  }
  
  /* 창 너비가 672px 이상일 때의 검색창 너비 */
  @media (min-width: 672px) {
    .content{
      width: 561px;
    }

    .app-title {
      font-size: 48px;
    }
    
    .app-logo {
      height: 60px;
    }

    .description-text, .example span {
      font-size: 20px; /* 스크린 크기가 더 커질 때 텍스트 크기 증가 */
    }
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border: 2px solid black;
    border-radius: 24px;
    position: relative;
    margin-top: 20px;
  }
  
  .search-input {
    border: none;
    border-radius: 24px;
    color: #000;
    font-family: inherit;
    font-size: inherit;
    height: 48px;
    padding-right: 110px;
    padding-left: 60px;
    width: 100%;
    background-color: transparent;
    outline:none;
  }
  
  .search-input::placeholder {
    color: #aaa;
  }
  
  .search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    color: #a3a3a3;
  }

  .clear-icon {
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #a3a3a3;
  }

  .search-button {
      position: absolute;
      right: -2px; 
      top: 0; 
      height: 100%; 
      padding: 0 20px; 
      background-color: black;
      color: white;
      border: 2px solid black; /* 조정된 값 */
      border-radius: 0 24px 24px 0; /* 조정된 값 */
      cursor: pointer;
  }


  .floating-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .floating-bar a {
    color: black;
    text-decoration: none;
    text-align: center;
    width: 20%; /* 버튼의 너비 지정 */
    display: flex; /* flex container로 만듦 */
    flex-direction: column; /* 세로 방향으로 아이템들을 정렬 */
    justify-content: space-evenly; /* 세로 방향으로 중앙 정렬 */
    align-items: center; /* 가로 방향으로 중앙 정렬 */
  }

  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-top: 80px;
    height: 120px;
  }

  .loading-spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }