:root {
    --base-size: 3;
}

.ipa-main-bundle {
    border: 1px solid #000;
    margin-right: 1px;
    position: relative;
}

/* A. final_consonant가 없는 경우 */

/* 우측모음인 경우 */
.main-first-consonant-right {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: calc(2 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 2;
}

.main-vowel-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: calc(1 / var(--base-size) * 100%);
    height: calc(3 / var(--base-size) * 100%);
    z-index: 1;
}

/* 하측모음인 경우 */
.main-first-consonant-bottom {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: calc(2 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 2;
}

.main-vowel-bottom {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: calc(3 / var(--base-size) * 100%);
    height: calc(1 / var(--base-size) * 100%);
    z-index: 1;
}

/* 전체모음인 경우 */
.main-first-consonant-full {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: calc(2 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 2;
}

.main-vowel-full {
    position: absolute;
    width: calc(3 / var(--base-size) * 100%);
    height: calc(3 / var(--base-size) * 100%);
    transform: none;
    z-index: 1;
}

/* B. final_consonant가 있는 경우 */

.main-final-consonant {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(2 / var(--base-size) * 100%);
    height: calc(1 / var(--base-size) * 100%);
    z-index: 2;
}

/* 우측모음인 경우 with final */
.main-first-consonant-right.with-final {
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    width: calc(2 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 2;
}

.main-vowel-right.with-final {
    position: absolute;
    top: 0;
    right: 0;
    transform: none;
    width: calc(1 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 1;
}

/* 하측모음인 경우 with final */
.main-first-consonant-bottom.with-final {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(2 / var(--base-size) * 100%);
    height: calc((4 / 3) / var(--base-size) * 100%);
    z-index: 2;
}

.main-vowel-bottom.with-final {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(3 / var(--base-size) * 100%);
    height: calc((2 / 3) / var(--base-size) * 100%);
    z-index: 1;
}

/* 전체모음인 경우 with final */
.main-first-consonant-full.with-final {
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    width: calc(2 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 2;
}

.main-vowel-full.with-final {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(3 / var(--base-size) * 100%);
    height: calc(2 / var(--base-size) * 100%);
    z-index: 1;
}

/* Hover 효과 */

.ipa-main-bundle img:hover {
    outline: 2px solid yellow;
}
