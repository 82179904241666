.system-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: white;
    margin-top: 50px;
    padding: 20px;
    box-sizing: border-box;
  }

.system-container .content{
    min-width: 300px; /* 설명 텍스트의 최대 너비를 제한 */
}

.system-container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 24px;
}
  
.system-container .app-title {
    color: black; /* 텍스트 색상을 흰색으로 설정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
    margin: 0; /* 제목을 화면 가운데에 위치시킴 */
    font-size: 24px;
  }

.system-container .description{
    margin-top: 20px; /* 제목과 설명 텍스트 사이의 간격 */
    padding: 10px; /* 텍스트 주변에 패딩 추가 */
    border-radius: 10px; /* 둥근 모서리 스타일링 */
    background-color: white; /* light grey background */
  }

.system-container .description-text {
    color: #000000; /* 텍스트 색상을 흰색으로 설정 */
    text-align: start;
    line-height: 1.5; /* 줄 간격을 조절 */
    font-size: 16px; /* 텍스트 크기 설정 */
}

.system-container .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 16px 0;
  }

/* 창 너비가 560px 이상일 때의 검색창 너비 */
@media (min-width: 560px) {
    .system-container .content {
        width: 560px;
    }

    .system-container .app-title {
        font-size: 40px;
    }  
}
    
    /* 창 너비가 672px 이상일 때의 검색창 너비 */
    @media (min-width: 672px) {
    .system-container .content{
        width: 672px;
    }

    .system-container .app-title {
        font-size: 48px;
    }
}
